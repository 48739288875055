var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":"mdi-handshake","title":"Vínculo dos Professores"},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('emc-excel-import-icon',{attrs:{"param":"teacher_contracts"},on:{"onCompleted":function($event){return _vm.getData()}}}),(_vm.isAnima())?_c('s-icon-report-download',{attrs:{"show-message":"","report":"42"}}):_c('emc-excel-export-icon',{attrs:{"report-id":"42"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Novo cadastro ")])])]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading,"searchLength":3},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"item.teacher",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.teacher.name)+" "),(item.registration)?_c('small',[_c('br'),_vm._v(_vm._s(item.registration))]):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('span',[_vm._v(_vm._s(item.company.name))]):_vm._e(),(item.branch)?_c('small',[_c('br'),_vm._v(_vm._s(item.branch.name))]):_vm._e(),(item.campuses && item.campuses.length)?_c('span',[_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getCampuses(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.campuses.map(function (o) { return o['name']; }).join(', ')))])])],1):_vm._e()]}}])}),_c('sys-register-teacher-contract',{attrs:{"model":_vm.model,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }